<template>
  <div>
    <el-dialog
      v-model="dialogVisible"
      :title="$t('general.router-reset-password')"
      :before-close="handleClose"
    >
      <resetPasswordForm ref="resetPasswordRef"></resetPasswordForm>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">{{
            $t('general.cancel')
          }}</el-button>
          <el-button type="primary" @click="handleResetPassword">{{
            $t('general.confirm')
          }}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script setup>
import { ref, getCurrentInstance, defineExpose, defineProps } from 'vue'
import resetPasswordForm from '../component/passwordForm.vue'

const props = defineProps({
  passWord: {
    type: String,
    default: ''
  }
})

const cxt = getCurrentInstance()

const handleMessageCommit =
  cxt.appContext.config.globalProperties.$handleMessageCommit

const dialogVisible = ref(false)

const handleClose = () => {
  dialogVisible.value = false
}

const resetPasswordRef = ref()

const handleResetPassword = async () => {
  const passwordForm = resetPasswordRef.value.passwordForm
  const valid = await resetPasswordRef.value.handleValidate()
  if (!valid) return
  handleMessageCommit('user/resetPassword', {
    passCode: props.passWord,
    newPassCode: passwordForm.newPassword,
    confirmPassCode: passwordForm.confirmPassword
  }).then(() => handleClose())
}

defineExpose({
  dialogVisible
})
</script>
<style lang="scss" scoped>
$dark_gray: #889aa4;

.show-pwd {
  position: absolute;
  right: 10px;
  font-size: 16px;
  color: $dark_gray;
  cursor: pointer;
  user-select: none;
}
</style>
