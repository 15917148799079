<template>
  <div class="login-wrapper">
    <div class="left-container"></div>
    <div class="login-container">
      <el-form
        class="login-form"
        ref="loginFormRef"
        :model="loginForm"
        :rules="loginValidates"
        :label-position="'top'"
      >
        <div class="title-container">
          <h3 class="title">{{ $t('login.please-sign-in') }}</h3>
          <div class="name">{{ $t('login.login-name') }}</div>
        </div>
        <!-- username -->
        <el-form-item prop="userName" label="User Name">
          <el-input
            :placeholder="'Enter your user name'"
            name="username"
            type="text"
            v-model="loginForm.userName"
          ></el-input>
        </el-form-item>
        <!-- password -->
        <el-form-item prop="passCode" label="Password">
          <el-input
            :placeholder="'Enter your user password'"
            name="username"
            :type="passwordType"
            v-model="loginForm.passCode"
          ></el-input>
          <span class="show-pwd">
            <svg-icon
              :icon="passwordType === 'password' ? 'eye' : 'eye-open'"
              @click="onChangePwdType"
            />
          </span>
        </el-form-item>
        <el-form-item class="randCode" prop="randCode" label="Security Code">
          <el-input
            :placeholder="$t('login.login-security-code')"
            name="randCode"
            v-model="loginForm.randCode"
            type="text"
          ></el-input>
        </el-form-item>
        <el-form-item class="randCode-img">
          <img
            :src="imgpath"
            :alt="$t('login.login-security-code')"
            @click="handleChangeCode"
          />
        </el-form-item>

        <el-checkbox v-model="isRemember" class="remember">{{
          $t('login.login-remember')
        }}</el-checkbox>
        <!-- <a class="forget_pwd" @click="handleForget">
        {{ $t('login.login-forget-password') }}</a
      > -->
        <!-- 登录按钮 -->
        <el-button
          type="primary"
          :loading="loading"
          class="login_btn"
          @click="handleLogin"
          >Login</el-button
        >
      </el-form>

      <el-dialog
        v-model="dialogVisible"
        :title="$t('login.login-forget-password')"
        :before-close="handleClose"
      >
        <el-divider style="margin-top: -10px"></el-divider>
        <el-form
          ref="emailForm"
          :model="form"
          label-width="160px"
          style="margin-left: 20%"
        >
          <el-form-item :label="$t('login.login-way-back')" required>
            <el-radio-group
              v-model="wayBack"
              size="large"
              @change="handleChange"
            >
              <el-radio-button :label="$t('login.login-email')" />
              <el-radio-button :label="$t('login.login-phone')" />
            </el-radio-group>
          </el-form-item>
          <template v-if="isShow">
            <el-form-item :label="$t('login.login-email-number')" required>
              <el-input
                v-model="emailForm.emailNumber"
                style="width: 60%"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('login.login-security-code')" required>
              <el-input
                v-model="emailForm.randCode"
                style="width: 36%; position: relative; top: -10px"
              ></el-input>
              <img
                :src="imgpath"
                :alt="$t('login.login-email-verify-code')"
                @click="handleChangeCode"
                class="dialog-randCode"
              />
            </el-form-item>
            <el-form-item :label="$t('login.login-email-verify-code')" required>
              <el-input
                v-model="emailForm.authCode"
                :placeholder="$t('general.input')"
                style="width: 60%"
              >
                <template #append>
                  <el-button
                    type="primary"
                    style="background-color: #409eff; color: white"
                    >{{ $t('login.login-get-email-code') }}</el-button
                  >
                </template>
              </el-input>
            </el-form-item>
          </template>
          <template v-else>
            <el-form-item :label="$t('login.login-phone-number')" required>
              <el-input
                v-model="phoneForm.phoneNumber"
                style="width: 60%"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('login.login-security-code')">
              <el-input
                v-model="phoneForm.randCode"
                style="width: 36%; position: relative; top: -10px"
              ></el-input>
              <img
                :src="imgpath"
                :alt="$t('login.login-security-code')"
                @click="handleChangeCode"
                class="dialog-randCode"
              />
            </el-form-item>
            <el-form-item :label="$t('login.login-message-code')" required>
              <el-input
                v-model="phoneForm.verifyPhoneCode"
                :placeholder="$t('general.input')"
                style="width: 60%"
              >
                <template #append>
                  <el-button
                    type="primary"
                    style="background-color: #409eff; color: white"
                    >{{ $t('login.login-get-message-code') }}</el-button
                  >
                </template>
              </el-input>
            </el-form-item>
          </template>
        </el-form>

        <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogVisible = false">{{
              $t('general.cancel')
            }}</el-button>
            <el-button type="primary" @click="dialogVisible = false">{{
              $t('general.confirm')
            }}</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
  <resetPasswordDialog
    :passWord="encryptionPassword"
    ref="resetPasswordDialogRef"
  />
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'
import moment from 'moment'
import { ElMessageBox, ElMessage } from 'element-plus'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import i18n from '@/i18n'
import { setItem, getItem, removeItem } from '@/utils/storage.js'
import {
  ISLOGIN,
  USERNAME,
  PASSWORD,
  DEFAULT_COLOR
} from '@/utils/constant/index'
import { Base64 } from 'js-base64'
import resetPasswordDialog from './dialog/resetPassword.vue'

const { t } = i18n.global
const isRemember = ref(false)

let randomNum = moment().valueOf()
const imgpath = ref('/auth/captcha/login?randNum=' + randomNum)
// 生成随机验证码
const handleChangeCode = () => {
  randomNum = moment().valueOf()
  imgpath.value = '/auth/captcha/login?randNum=' + randomNum
}
// 弹框的显示与否
const dialogVisible = ref(false)

// const handleForget = () => {
//   dialogVisible.value = true
// }
const wayBack = ref('Email')
const isShow = ref(true)
const handleChange = (val) => {
  isShow.value = val === 'Email'
}
const emailForm = ref({
  emailNumber: '',
  randCode: '',
  authCode: ''
})
const phoneForm = ref({
  phoneNumber: '',
  randCode: '',
  verifyPhoneCode: ''
})

const handleClose = (done) => {
  ElMessageBox.confirm(t('general.sure-close-dialog'))
    .then(() => {
      done()
    })
    .catch(() => {
      // catch error
    })
}

// 数据源
const loginForm = ref({
  userName: '',
  passCode: '',
  randCode: ''
})

// 验证规则
const loginValidates = ref({
  userName: [
    {
      validator: (rule, value, callback) => {
        if (value === '') {
          callback(new Error(t('validator.username-is-required')))
        } else {
          callback()
        }
      }
    }
  ],
  passCode: [
    {
      validator: (rule, value, callback) => {
        if (value === '') {
          callback(new Error(t('validator.password-is-required')))
        } else {
          callback()
        }
      }
    }
  ],
  randCode: [
    {
      validator: (rule, value, callback) => {
        if (value === '') {
          callback(new Error(t('validator.security-code-is-required')))
        } else {
          callback()
        }
      }
    }
  ]
})

onMounted(() => {
  const username = getItem(USERNAME)
  if (username) {
    loginForm.value.userName = getItem(USERNAME)
    loginForm.value.passCode = Base64.decode(getItem(PASSWORD)) // base64解密
    isRemember.value = true
  }
  // 添加keydown事件
  window.addEventListener('keydown', handleKeyDown)
})

// 处理密码框的文本显示状态
const passwordType = ref('password')
const onChangePwdType = () => {
  passwordType.value = passwordType.value === 'password' ? 'text' : 'password'
}
// 登录状态的相关处理
const loginFormRef = ref(null)
const resetPasswordDialogRef = ref('')
const encryptionPassword = ref('')

const loading = ref(false)
const store = useStore()
const router = useRouter()
const handleLogin = () => {
  loginFormRef.value.validate((valid) => {
    console.log(valid)
    if (!valid) return
    loading.value = true
    if (isRemember.value) {
      const password = Base64.encode(loginForm.value.passCode) // base64加密
      setItem(USERNAME, loginForm.value.userName)
      setItem(PASSWORD, password)
    } else {
      removeItem(USERNAME)
      removeItem(PASSWORD)
    }
    store
      .dispatch('user/login', {
        randomNum,
        ...loginForm.value
      })
      .then((res) => {
        loading.value = false
        const { code, resultCode, msg } = res
        if (resultCode === 'SUCCESS' || code === 200) {
          ElMessage({
            message: t('login.login-success'),
            type: 'success'
          })
          // 登录后操作
          router.push('/')
          // 记录已登录的标识
          setItem(ISLOGIN, 'logged')
          store.dispatch('app/getMenuBtnPermissionList')
          const themeColor = res?.data?.themeColor || DEFAULT_COLOR
          store.commit('app/setThemeColor', themeColor)
        } else if (code === 199) {
          // 密码过期
          ElMessageBox.confirm(
            t('popup.password-expire-tip'),
            t('general.router-reset-password'),
            {
              confirmButtonText: t('general.router-change-password'),
              cancelButtonText: t('popup.confirm-cancel-btn'),
              type: 'warning'
            }
          )
            .then(async () => {
              resetPasswordDialogRef.value.dialogVisible = true
              encryptionPassword.value = res?.data || ''
            })
            .catch(() => {
              ElMessage({
                type: 'info',
                message: t('popup.cancel-tip')
              })
            })
        } else {
          ElMessage({
            message: msg,
            type: 'error'
          })
          // if (resultMessage === 'random code error') {
          handleChangeCode()
          // loginForm.value.randCode = ''
          // }
        }
      })
      .catch((err) => {
        console.log(err)
        loading.value = false
      })
  })
}

const handleKeyDown = (e) => {
  if (e.keyCode === 13) {
    handleLogin()
  }
}

onUnmounted(() => {
  window.removeEventListener('keydown', handleKeyDown, false)
})

// 处理登录请求
</script>

<style lang="scss" scoped>
$bg: #696a7a;
$dark_gray: #889aa4;
$light_gray: rgba(0, 0, 0, 0.699);
$cursor: #696a7a;
.login-wrapper {
  width: 100%;
  height: 100vh;
  background-image: url('../../assets/imgs/loginPageBgImg.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
}
.left-container {
  height: 100vh; /* 设置高度为视口高度的100% */
  width: 60%; /* 设置宽度为50% */
  position: fixed; /* 使用fixed定位，使其脱离文档流 */
  top: 0; /* 顶部对齐 */
}
.login-container {
  height: 100vh; /* 设置高度为视口高度的100% */
  width: 40%; /* 设置宽度为50% */
  position: fixed; /* 使用fixed定位，使其脱离文档流 */
  top: 0; /* 顶部对齐 */
}
.left-container {
  left: 0; /* 左侧对齐 */
  // background-image: url('../../assets/imgs/loginPageBg.png');
}
.login-container {
  height: 100%;
  width: 40%;
  right: 0; /* 右侧对齐 */
  // background-color: rgb(246, 250, 255); /* 背景色示例 */
  /*background-color: $bg;*/

  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  .login-form {
    // background-color: white;
    top: -20px;
    position: relative;
    width: 400px;
    max-width: 100%;
    margin: auto auto;
    overflow: hidden;
    // border: 1px solid #ccc;
    border-radius: 10px;
    padding: 50px 45px;

    :deep(.el-form-item__label) {
      flex: 0 0 auto;
      // text-align: right;
      font-size: 16px;
      color: var(--el-text-color-regular);
      line-height: 40px;
      padding: 0 12px 0 0;
      box-sizing: border-box;
      font-weight: bold;
    }
    :deep(.el-input__inner) {
      -webkit-appearance: none;
      background-color: var(--el-input-background-color, var(--el-color-white));
      background-image: none;
      border-radius: var(
        --el-input-border-radius,
        var(--el-border-radius-base)
      );
      border: var(--el-input-border, var(--el-border-base));
      box-sizing: border-box;
      color: var(--el-input-font-color, var(--el-text-color-regular));
      display: inline-block;
      font-size: 15.5px;
      height: 48px;
      line-height: 48px;
      outline: 0;
      padding: 0 15px;
      transition: var(--el-transition-border);
      width: 100%;
    }
    .randCode {
      width: 56%;
      display: inline-block;
      position: relative;
      top: -15px;
    }

    .randCode-img {
      width: 36%;
      margin-left: 6%;
      margin-top: 5px;
      height: 48px;
      display: inline-block;
      background-color: #fff;
      position: relative;
      bottom: 0px;
      // width: 36%;
      // display: inline-block;
      // margin-left: 25px;
      img {
        width: 100%;
        height: 48px;
      }
    }

    .remember {
      color: #696a7a;
      font-size: 14px;
      font-weight: bold;
      position: relative;
      top: -25px;
    }

    .forget_pwd {
      color: #696a7a;
      line-height: 40px;
      display: inline-block;
      float: right;
      font-size: 14px;
      font-weight: bold;
      position: relative;
      top: -16px;
    }

    .login_btn {
      width: 100%;
      font-weight: bold;
      font-size: 16px;
      background-color: #0071f5;
      margin-top: -10px;
    }
  }

  .svg-container {
    padding: 0px 5px 0px 5px;
    color: $dark_gray;
    vertical-align: middle;
    display: inline-block;
    .form-icon {
      width: 26px;
      height: 26px;
      vertical-align: middle;
    }
  }

  .title-container {
    position: relative;

    .title {
      font-size: 32px;
      color: $light_gray;

      font-weight: bold;
    }
    .name {
      font-size: 22px;
      color: $light_gray;

      margin: 15px 0 20px 0;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }
  .dialog-randCode {
    height: 38px;
    width: 18%;
    position: relative;
    left: 30px;
    top: 3px;
    border-radius: 3px;
  }
}
</style>
