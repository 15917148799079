<template>
  <el-form
    class="password-form"
    ref="formRef"
    :model="passwordForm"
    :rules="passwordValidates"
    label-width="160px"
    style="margin: 0 20%"
  >
    <el-form-item :label="$t('profile.new-password')" prop="newPassword">
      <el-input
        v-model="passwordForm.newPassword"
        :type="forgetPasswordType"
        maxlength="20"
      ></el-input>
      <span class="show-pwd">
        <svg-icon
          :icon="forgetPasswordType === 'password' ? 'eye' : 'eye-open'"
          @click="onChangeForgetPwdType"
        />
      </span>
    </el-form-item>
    <el-form-item
      :label="$t('profile.confirm-password')"
      prop="confirmPassword"
    >
      <el-input
        v-model="passwordForm.confirmPassword"
        :type="comfirmPasswordType"
        maxlength="20"
      ></el-input>
      <span class="show-pwd">
        <svg-icon
          :icon="comfirmPasswordType === 'password' ? 'eye' : 'eye-open'"
          @click="onChangeConfirmPwdType"
        />
      </span>
    </el-form-item>
  </el-form>
</template>
<script setup>
import { ref, defineExpose } from 'vue'
import i18n from '@/i18n'
import Validator from '@/validators'

const formRef = ref(null)
const { t } = i18n.global

// 处理忘记密码框的文本显示状态
const forgetPasswordType = ref('password')
const onChangeForgetPwdType = () => {
  forgetPasswordType.value =
    forgetPasswordType.value === 'password' ? 'text' : 'password'
}

// 处理忘记密码确认密码的文本显示状态
const comfirmPasswordType = ref('password')
const onChangeConfirmPwdType = () => {
  comfirmPasswordType.value =
    comfirmPasswordType.value === 'password' ? 'text' : 'password'
}

const passwordForm = ref({
  newPassword: '',
  confirmPassword: ''
})
var newPassCodeValue = ''

const newPwdValidator = (rule, value, callback) => {
  newPassCodeValue = value
  callback()
}

const confirmPwdValidator = (rule, value, callback) => {
  if (value !== newPassCodeValue) {
    return callback(new Error(i18n.global.t('general.code-not-match')))
  } else {
    callback()
  }
}

const passwordValidates = ref({
  newPassword: [
    {
      required: true,
      trigger: 'blur',
      message: t('general.required')
    },
    {
      validator: Validator.regex,
      regex:
        /^(?:(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[:;?.,<>()^`![|~\\@#$%&*~])).{8,20}$/,
      message: t('validator.new-password-validate')
    },
    { validator: newPwdValidator, trigger: 'blur' }
  ],
  confirmPassword: [
    {
      required: true,
      trigger: 'blur',
      message: t('general.required')
    },
    { validator: confirmPwdValidator, trigger: 'blur' }
  ]
})

const handleValidate = async () => {
  let result = false
  await new Promise((resolve) => {
    formRef.value.validate((valid) => {
      result = valid
      resolve()
    })
  })
  return result
}

defineExpose({
  handleValidate,
  passwordForm
})
</script>
<style lang="scss" scoped>
$dark_gray: #889aa4;
.password-form {
  .el-form-item {
    margin-bottom: 33px;
  }
}
.show-pwd {
  position: absolute;
  right: 10px;
  font-size: 16px;
  color: $dark_gray;
  cursor: pointer;
  user-select: none;
}
</style>
